/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { StatusConstant } from '../../constants/status.constant'; //// CONTROLLER ////

(function () {
  'use strict';

  angular.module('comcast.lite').controller('ViewGridController', ['$scope', '$state', '$stateParams', '$timeout', 'uiGridConstants', 'LiteDeliveryResourceFactory', 'AssetResourceFactory', 'socket', function ($scope, $state, $stateParams, $timeout, uiGridConstants, LiteDeliveryResourceFactory, AssetResourceFactory, socket) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    var statusConstants = new StatusConstant(); // Store the search params in memory as we go

    var SearchParams = {};
    vm.spotStatuses = angular.copy(statusConstants.spots);
    vm.deliveriesStatuses = angular.copy(statusConstants.deliveries);
    vm.deliveriesStatuses.COMPLETED.status = 'Delivered';
    /* BINDABLE : DATA */

    vm.spotThumbs = [];
    vm.paginationOptions = {
      pageNumber: 1,
      pageSize: 25,
      sortField: null,
      sortDirection: null
    };
    vm.gridOptions = {
      paginationPageSizes: [25, 50, 75],
      paginationPageSize: 25,
      paginationCurrentPage: vm.paginationOptions.pageNumber,
      useExternalPagination: true,
      useExternalSorting: true,
      enableColumnMenus: false,
      enableRowSelection: true,
      enableRowHeaderSelection: false,
      modifierKeysToMultiSelect: true,
      multiSelect: true,
      appScopeProvider: vm,
      rowHeight: 50,
      rowTemplate: '<div ng-dblclick="grid.appScope.openDetail(row.entity)" mbl-dblclick="grid.appScope.openDetail(row.entity)" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="[\'ui-grid-cell\', { \'ui-grid-row-header-cell\': col.isRowHeader}]" id="viewRow_{{$index}}_viewgrid_row"  ui-grid-cell></div>',
      columnDefs: [// thumb, title, client, isci/spot #, destination, status, created date
      {
        name: 'thumb',
        field: 'name',
        displayName: 'thumb',
        enableSorting: false,
        width: 65,
        cellTemplate: '<div class="ui-grid-cell-contents" layout="row" layout-align="center center" style="min-height:1px;"><img height="100%" class="fit-for-ie" ng-src="{{grid.appScope.spotThumbs[row.entity.isci]}}" alt="thumbnail" ng-if="grid.appScope.spotThumbs[row.entity.isci]"></div>',
        headerCellTemplate: require('../settings/customHeader-template.html'),
        headerCellClass: 'thumb_viewgrid_header'
      }, {
        name: 'title',
        field: 'title',
        displayName: 'title',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: require('../settings/customHeader-template.html'),
        headerCellClass: 'title_viewgrid_header'
      }, {
        name: 'clientName',
        field: 'Advertiser.name',
        displayName: 'client',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: require('../settings/customHeader-template.html'),
        headerCellClass: 'client_viewgrid_header'
      }, {
        name: 'isci',
        field: 'expressIsci',
        displayName: 'isci/spot #',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        cellTemplate: '<div class="ui-grid-cell-contents">{{(row.entity.expressIsci) ? row.entity.expressIsci : row.entity.isci}}</div>',
        headerCellTemplate: require('../settings/customHeader-template.html'),
        headerCellClass: 'isci_viewgrid_header'
      }, {
        name: 'destination',
        field: 'ParentOrderItem.Destination.name',
        displayName: 'destination',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: require('../settings/customHeader-template.html'),
        headerCellClass: 'destination_viewgrid_header'
      }, {
        name: 'status',
        field: 'status',
        displayName: 'status',
        enableSorting: false,
        width: 250,
        cellTemplate: '<div class="ui-grid-cell-contents"><md-tooltip md-direction="top" style="margin-top: 5px;">{{grid.appScope.checkLabel(row.entity)}}</md-tooltip><cts-status-bar data="grid.appScope.checkStatus(row.entity)" ng-show="row.entity.ParentOrderItem"></cts-status-bar><cts-status-bar data="grid.appScope.spotStatuses[row.entity.status]" ng-hide="row.entity.ParentOrderItem"></cts-status-bar></div>',
        headerCellTemplate: require('../settings/customHeader-template.html'),
        headerCellClass: 'status_viewgrid_header'
      }, {
        name: 'createdDate',
        field: 'date_created',
        cellFilter: "date:'MM/dd/yy h:mm:ss a'",
        displayName: 'created',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: require('../settings/customHeader-template.html'),
        headerCellClass: 'created_viewgrid_header'
      }],
      onRegisterApi: function onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
          if (sortColumns && sortColumns.length == 0) {
            vm.paginationOptions.sort = null;
          } else {
            vm.paginationOptions.sortField = sortColumns[0].field;
            vm.paginationOptions.sortDirection = sortColumns[0].sort.direction.toUpperCase();
          }

          vm.getPage(SearchParams);
        });
        gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
          vm.paginationOptions.pageNumber = newPage;
          vm.paginationOptions.pageSize = pageSize;
          vm.getPage(SearchParams);
        });
      }
    };
    /* EVENTS */

    socket.on('Spot_Ingest_Progress', function (ingestStatus) {
      for (var i = 0; i < vm.gridOptions.data.length; i++) {
        if (vm.gridOptions.data[i].id === undefined) {
          continue;
        } else if (vm.gridOptions.data[i].id === ingestStatus.spotId) {
          vm.gridOptions.data[i].status = ingestStatus.ingestStatus;

          if (['PROCESSING', 'VERIFYING'].indexOf(ingestStatus.ingestStatus) > -1) {
            vm.gridOptions.data[i].progress = ingestStatus.ingestPercent;
          } else {
            vm.gridOptions.data[i].progress = null;
          }
        }
      }
    });
    /* BINDABLE : METHODS */
    //Have to switch back and forth between 'Long Duration Delivered' and 'Delivered'

    vm.checkLabel = function (spot) {
      return spot.ParentOrderItem ? vm.deliveriesStatuses[spot.ParentOrderItem.deliveryStatus].label : vm.spotStatuses[spot.status].label;
    };

    vm.checkStatus = function (spot) {
      var status = {}; // As lite submits, these HAVE to had an orderItem
      // in PENDING_MEDIA, we want to show the spot status instead of the orderItem status.

      if (spot.ParentOrderItem && spot.ParentOrderItem.deliveryStatus !== 'PENDING_MEDIA') {
        status = vm.deliveriesStatuses[spot.ParentOrderItem.deliveryStatus];

        if (spot.duration > spot.expectedDuration && spot.ParentOrderItem.deliveryStatus === 'COMPLETED') {
          status.status = 'Long Duration Delivered';
        } else if (spot.ParentOrderItem.deliveryStatus === 'COMPLETED') {
          status.status = 'Delivered';
        }
      } else {
        status = vm.spotStatuses[spot.status];
      }

      if (spot.status === 'UNSENT' && spot.reslateFlag) {
        status.status = 'Reslate Needed';
        status.label = 'The spot metadata has changed and needs a re-slate.';
      }

      status.progress = spot.progress;
      return status;
    };

    vm.getPage = function (params) {
      params = params !== undefined ? params : {};
      params.limit = vm.paginationOptions.pageSize;
      params.offset = vm.paginationOptions.pageSize * (vm.paginationOptions.pageNumber - 1);
      params.sortField = vm.paginationOptions.sortField;
      params.sortDirection = vm.paginationOptions.sortDirection; // COM-11612 - this is a hack to get around the fact that title is often entered with a colon in it.
      // The colon messes up the Node parsing of the field, so pass this one like admin does

      var tempParams = angular.copy(params);

      if (params.title) {
        tempParams.title = 'like:' + params.title;
      }

      LiteDeliveryResourceFactory.getAll(tempParams, function (deliveries) {
        vm.gridOptions.totalItems = deliveries.data.count;
        vm.gridOptions.data = deliveries.data.rows.map(function (data, ind, list) {
          if (data.ThumbnailAsset && data.ThumbnailAsset.AssetContent && data.ThumbnailAsset.AssetContent.contentUuid) {
            vm.getThumbnail(data.isci, data.ThumbnailAsset.AssetContent.contentUuid);
          }

          return data;
        });
      }, function errorHandler(err) {
        // Something went wrong, so display nothing
        vm.gridOptions.totalItems = 0;
        vm.gridOptions.data = [];
      });
    };

    vm.getThumbnail = function (isci, uuid) {
      AssetResourceFactory.getImageAsset({
        uuid: uuid
      }, {}, function success(asset) {
        var blob = new Blob([asset.data], {
          type: asset.config['Content-Type']
        });
        vm.spotThumbs[isci] = URL.createObjectURL(blob);
      }, function failure(err) {
        console.log('FAILURE');
        console.log(err);
      });
    };

    vm.openDetail = function (row) {
      angular.element(document.querySelector('.sidebar')).addClass('invisible');
      $state.go('viewDetail', {
        id: row.id,
        pageNumber: vm.paginationOptions.pageNumber
      }).then(function () {
        // Apply the animation ONLY when this transition happens
        $timeout(function () {
          angular.element(document.querySelector('.sidebar')).removeClass('invisible');
          angular.element(document.querySelector('.detail-list-area .side-grid')).addClass('slide-left-transition');
        }, 0);
      });
    };
    /* EVENTS */
    //Register any event listeners


    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      // Wait for the current digest cycle to finish, so the grid will display properly
      $timeout(function () {
        // Utilize parameters
        SearchParams = toParams;
        vm.getPage(SearchParams);
      });
    });
    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      if ($stateParams.pageNumber && $stateParams.pageNumber !== 0) {
        vm.paginationOptions.pageNumber = parseInt($stateParams.pageNumber, 10);
        vm.gridOptions.paginationCurrentPage = vm.paginationOptions.pageNumber;
      }
    }
  }]);
})();