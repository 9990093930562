/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2020 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

// import { Inject } from '@angular/core';
//
// import { WINDOW } from "../common/communication/window-provider"

//// CONTROLLER ////
export class ViewSearchController {
    /* DECLARE: CLASS PROPERTIES */
    // Injected
    private $state: any;
    private $stateParams: any;
    // Bindable
    public searchParams: object;

    static get $inject() {
        return ['$state', '$stateParams'];
    }
    // @Inject(WINDOW) private window: Window,
    constructor($state: any, $stateParams: any) {
        // console.log(this.window);
        /* INJECTED: DEPENDENCIES */

        this.$state = $state;
        this.$stateParams = $stateParams;

        /* BINDABLE: DATA */
        this.searchParams = {};
    }

    //////////////////////
    //START FUNCTION BLOCK
    //////////////////////
    search() {
        let vm = this;
        vm.$state.go('viewList', vm.searchParams, {
            notify: true,
            inherit: false,
            reload: true
        });
    }

    clear() {
        let vm = this;
        vm.searchParams = {};
    }

    $onInit() {
        //Do init things
        let vm = this;
        vm.searchParams = vm.$stateParams;
    }
}
