/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.lite').controller('ThumbnailDialogController', ['$state', '$mdDialog', 'loginService', 'storyBoard', 'thumbnailIndex', 'AssetResourceFactory', 'NotificationService', function ($state, $mdDialog, loginService, storyBoard, thumbnailIndex, AssetResourceFactory, NotificationService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.uuid = storyBoard[thumbnailIndex];
    vm.currentThumbnail = thumbnailIndex;
    vm.lastThumbnail = storyBoard.length - 1;
    vm.alias = 'thumbnail_' + vm.uuid.substring(vm.uuid.lastIndexOf('_') + 1);
    vm.thumbnail = null;
    vm.showOverlay = false;
    /* BINDABLE : METHODS*/

    vm.close = close;
    vm.downloadAsset = downloadAsset;
    vm.displayOverlay = displayOverlay;
    vm.gotoThumb = gotoThumb;
    vm.hasRoles = hasRoles;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function close() {
      $mdDialog.cancel();
    }

    function displayOverlay() {
      vm.showOverlay = !vm.showOverlay;
    }

    function downloadAsset(uuid, alias) {
      NotificationService.showNotificationToast('Download(s) has started.');
      AssetResourceFactory.downloadAsset({
        uuid: uuid
      }, {}, function success(asset) {
        var url = URL.createObjectURL(asset.data.data);
        var downloadLink = document.createElement('A');
        downloadLink.href = url;
        downloadLink.download = alias;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(asset.data.data);
      }, function failure(err) {
        console.log(err);
      });
    }

    function gotoThumb(index) {
      if (index < 0 || index > storyBoard.length - 1) {
        return;
      }

      vm.currentThumbnail = index;
      AssetResourceFactory.getImageAsset({
        uuid: storyBoard[index]
      }, {}, function success(asset) {
        vm.uuid = storyBoard[index];
        vm.alias = 'thumbnail_' + vm.uuid.substring(vm.uuid.lastIndexOf('_') + 1);
        var blob = new Blob([asset.data], {
          type: asset.config['Content-Type']
        });
        vm.thumbnail = URL.createObjectURL(blob);
      }, function failure(err) {
        console.log('FAILURE');
        console.log(err);
      });
    }

    function hasRoles(roles) {
      return loginService.hasPermission(roles.split(','));
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      // Retrieve the information we need
      AssetResourceFactory.getImageAsset({
        uuid: vm.uuid
      }, {}, function success(asset) {
        var blob = new Blob([asset.data], {
          type: asset.config['Content-Type']
        });
        vm.thumbnail = URL.createObjectURL(blob);
      }, function failure(err) {
        console.log('FAILURE');
        console.log(err);
      });
    }
  }]);
})();