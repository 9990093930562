/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { ViewSearchController } from './viewSearch-controller.ts';
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.lite', 'ngMaterial', 'ngMessages', 'comcast.directives', 'ui.router'];
var defaultName = angular.module('comcast.lite.detail', dependencies).config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state('viewList', {
    // Specify params that this state can access from the URL
    url: '/detail?title&isci&client&pageNumber',
    parent: 'views',
    // Specify default values for state params
    params: {
      name: null,
      activeOnly: false,
      pageNumber: null
    },
    reloadOnSearch: true,
    views: {
      'viewContent@views': {
        template: '<ui-view name="detailContent" class="detail-content"/>'
      },
      'viewSearch@views': {
        template: '<ui-view name="detailSearch"  class="detail-search" />'
      },
      'detailContent@viewList': {
        template: require('./viewGrid-template.html'),
        controller: 'ViewGridController',
        controllerAs: 'vm'
      },
      'detailSearch@viewList': {
        template: require('./viewSearch-template.html'),
        controller: ViewSearchController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('viewDetail', {
    url: '/view/:id',
    parent: 'viewList',
    params: {
      gridData: null,
      pageData: null
    },
    views: {
      detailContent: {
        template: require('./viewDetail-template.html'),
        controller: 'ViewDetailController',
        controllerAs: 'vm'
      },
      detailSearch: {
        template: require('./viewDetailList-template.html'),
        controller: 'ViewDetailListController',
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };