/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.lite').controller('MetadataReportController', ['$state', '$mdDialog', 'AssetResourceFactory', 'reportData', function ($state, $mdDialog, AssetResourceFactory, reportData) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.report = {};
    /* BINDABLE : METHODS*/

    vm.close = close;
    vm.stripUnderscores = stripUnderscores;
    vm.spaceUnderscores = spaceUnderscores;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function close() {
      $mdDialog.cancel();
    }

    function stripUnderscores(value) {
      return value.replace(/_/g, '');
    }

    function spaceUnderscores(value) {
      return value.replace(/_+/g, ' ');
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      if (reportData.hasOwnProperty && reportData.hasOwnProperty('General')) {
        vm.report = reportData;
      } else {
        // Retrieve the information we need
        AssetResourceFactory.getQcReportData({
          assetContentId: reportData
        }, {}, function success(qcReport) {
          vm.report = qcReport.data.metadataSource;
        }, function failure(err) {});
      }
    }
  }]);
})();