/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.lite').controller('ViewController', ['$state', '$scope', '$mdDialog', function ($state, $scope, $mdDialog) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.currentState = $state.current.name;
    vm.stateId = null;
    vm.spot = null;
    /* BINDABLE : METHODS*/

    vm.openSystemEvents = openSystemEvents;
    /* EVENTS */
    //Register any event listeners

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams) {
      vm.stateId = toParams.id;
      vm.currentState = $state.current.name;
    });
    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function openSystemEvents(spotId) {
      $mdDialog.show({
        controller: 'SystemEventsDialogController',
        controllerAs: 'vm',
        template: require('../common/dialogs/systemEventsDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: true,
        locals: {
          id: spotId,
          type: 'liteOrder'
        }
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {//TODO: Initialize this controller
    }
  }]);
})();